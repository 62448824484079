// import dynamic from "next/dynamic";

import SEO from "@/Components/utils/seo";
import { useEffect } from "react";
// import { useState } from "react";
// import { homeFaqData } from "@/constants/home/homeFaqData";

// const NewHeaderSV = dynamic(() => import("@/Components/common/new-header-sv"));

// const HeroSection = dynamic(() => import("@/Components/home/HeroSection"));
// const WebsiteDesigningSection = dynamic(
//   () => import("@/Components/home/WebsiteDesigningSection"),
//   {
//     ssr: false,
//   }
// );

// const VideoInvites = dynamic(() => import("@/Components/home/VideoInvites"), {
//   ssr: true,
// });
// const GallerySection = dynamic(
//   () => import("@/Components/home/GallerySection"),
//   {
//     ssr: false,
//   }
// );

// const Budget = dynamic(() => import("@/Components/home/Budget"), {
//   ssr: false,
// });
// const Feedbacks = dynamic(() => import("@/Components/home/Feedbacks"), {
//   ssr: false,
// });

// const Footer = dynamic(() => import("@/Components/common/footer-sv"), {
//   ssr: false,
// });
// const WhatsappFloatingIcon = dynamic(
//   () => import("@/Components/common/whatsapp-floating-icon-sv"),
//   {
//     ssr: false,
//   }
// );

// const LandingPageOfferPopup = dynamic(
//   () => import("@/Components/home/LandingPageOfferPopup"),
//   {
//     ssr: false,
//   }
// );

// const FAQSection = dynamic(
//   () => import("@/Components/home/common/FAQSection"),
//   {
//     ssr: false,
//   }
// );
// const InvitationPdf = dynamic(() => import("@/Components/home/InvitationPdf"), {
//   ssr: false,
// });
// const Blog = dynamic(() => import("@/Components/home/Blog"), {
//   ssr: false,
// });
// const SHOW_POPUP = false;

export default function Home() {
  // const [open, setOpen] = useState(false);
  function websiteJsonLd() {
    return {
      __html: `
      {	
        "@context": "https://www.schema.org",
        "@type": "localbusiness",
        "name": "ShaadiVines - India's leading brand for Digital Wedding Invitations",
        "telephone": "08800191621",
        "url": "https://www.shaadivines.com/",
        "logo": "https://www.shaadivines.com/assets/Logo.svg",
        "image": "https://www.shaadivines.com/assets/home/hero2.webp",
        "pricerange": "Affordable",
        "description": "Shaadi Vines is a one-stop shop for all your wedding needs. We offer a wide range of services, including video invitations, pdf invitation & wedding website.",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.shaadivines.com/search?q={search_term_string}",
          "query-input": "required name=search_term_string",
          "inLanguage": "en-US"
        }
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "India"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "21.1832788",
          "longitude": "82.7036649"
        },
        "hasMap": "https://maps.app.goo.gl/yBBB2hEv1W8vDyZDA", 
        "openingHours": "Mo, Tu, We, Th, Fr, Sa 09:00 AM - 08:00 PM Su 09:00 AM - 07:00",
           "contactPoint": {
           "@type": "PostalAddress",
           "contactType": "Customer Service",
           "telephone": "08800191621"
        },
         "aggregateRating" : {
            "@type" : "AggregateRating",
            "ratingValue" : "5",
            "bestRating" : "5",
            "worstRating" : "1",
            "ratingCount" : "54"
          },
          "review" : {
            "@type" : "Review",
            "author" : {
              "@type" : "Person",
              "name" : "Shruti Khurana"
            },
            "datePublished" : "2024-03-08",
            "reviewRating" : {
              "@type" : "Rating",
              "ratingValue" : "5",
              "bestRating" : "5",
              "worstRating" : "1"
            },
            "reviewBody" : "Very good services and very helpful people"
          }
         }
      `,
    };
  }

  useEffect(() => {
    console.log(window.location);
    let hostname = window.location.origin;

    window.location.href =
      hostname +
      "/designs/wedding-video-invitation/?labels=hindu_wedding&sortDirection=DESC&invites=wedding-video-invitation";
  }, []);
  // useEffect(() => {
  //   SHOW_POPUP &&
  //     setTimeout(() => {
  //       setOpen(true);
  //     }, 10000);
  // }, []);

  return (
    <main>
      <SEO
        title="Digital Wedding Invitation | PDF and Video Invitation | Budget Planning | Shaadi Vines"
        description="Shaadi Vines is a destination where you can create and customize your digital wedding invitation in the form of PDF and Video invitation. We offer Budget Planning and many more. Contact us now!"
        keywords="Digital Invitations"
        url="https://www.shaadivines.com/"
        ogImage="/favicon.png">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={websiteJsonLd()}
        />
      </SEO>

      {/*/!* <TopBar /> *!/*/}

      {/* <NewHeaderSV pageType="guest" />

      <HeroSection />
      <VideoInvites />
      <WebsiteDesigningSection />
      <InvitationPdf />
      <GallerySection />
      <Budget />
      <section className="py-10">
        <FAQSection data={homeFaqData} />
      </section>
      <Feedbacks />
      <Blog />
      <Footer />
      <WhatsappFloatingIcon />
      {SHOW_POPUP && <LandingPageOfferPopup open={open} setOpen={setOpen} />} */}
    </main>
  );
}
